import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLatest } from 'ahooks'
import { useCountdown } from '@/utils/hooks/useCountdown'
import styles from '../index.module.scss'
import { useTranslation } from 'next-i18next'

// 当前时间离结束时间超过7天，不显示倒计时
// 当前时间离结束时间不超过7天，且超过1天，以“天”为单位倒计时，显示n天，如2天
// 当前时间离结束时间在1天内，以“秒”为单位倒计时：HH时MM分SS秒倒计时，如23时03分45秒

const startWithTime = (t) => {
  if (t === 0) return '00'
  if ((t + '').length === 1) {
    return '0' + t
  }
  return t
}

const PcDown = ({ isShowDay, dayDiff, formattedRes }) => {
  const { t } = useTranslation('home')

  const { hours, minutes, seconds } = formattedRes

  return (
    <div className="flex items-center">
      <div className="text-[22px] font-[400] text-[#191919]">{t('ExpiresIn')}</div>
      {isShowDay ? (
        <div className="flex items-center ml-[12px]">
          <div className={`${styles['pc-block']}`}>{dayDiff}</div>
          <span className="ml-[8px] text-[22px] text-[#191919]">{t('ExpiresDays')}</span>
        </div>
      ) : (
        <div className="flex items-center ml-[12px]">
          <div className={`${styles['pc-block']}`}>{startWithTime(hours)}</div>
          <span className="mx-[4px] !w-[fit-content]">:</span>
          <div className={`${styles['pc-block']}`}>{startWithTime(minutes)}</div>
          <span className="mx-[4px] !w-[fit-content]">:</span>
          <div className={`${styles['pc-block']}`}>{startWithTime(seconds)}</div>
        </div>
      )}
    </div>
  )
}
const Down = ({ isShowDay, dayDiff, formattedRes }) => {
  const { t } = useTranslation('home')
  const { hours, minutes, seconds } = formattedRes

  return (
    <div className="flex flex-col items-center">
      <div className="text-[14px] font-[600] text-[#171512] ">{t('ExpiresIn')}</div>
      {isShowDay ? (
        <div className="flex items-center ml-[12px]">
          <div className={`${styles['m-block']}`}>{dayDiff}</div>
          <span className="ml-[8px] text-[#171512] ">{t('ExpiresDays')}</span>
        </div>
      ) : (
        <div className="flex items-center ml-[12px]">
          <div className={`${styles['m-block']}`}>{startWithTime(hours)}</div>
          <div className="mx-[4px] !w-[fit-content] text-[#fff]">:</div>
          <div className={`${styles['m-block']} `}>{startWithTime(minutes)}</div>
          <div className="mx-[4px] !w-[fit-content] text-[#fff]">:</div>
          <div className={`${styles['m-block']}`}>{startWithTime(seconds)}</div>
        </div>
      )}
    </div>
  )
}

const DialogDown = ({ isShowDay, dayDiff, formattedRes }) => {
  const { hours, minutes, seconds } = formattedRes
  return (
    <div className="flex flex-col items-center">
      <div className="text-[16px] font-[600] text-[#fff] ">EXPIRES IN</div>
      {isShowDay ? (
        <div className="flex items-center ml-[12px]">
          <div className={`${styles['dialog-block']}`}>{dayDiff}</div>
          <span className="ml-[8px] text-[#fff]">Days</span>
        </div>
      ) : (
        <div className="flex items-center ml-[12px]">
          <div className={`${styles['dialog-block']}`}>{startWithTime(hours)}</div>
          <div className="mx-[4px] !w-[fit-content] text-[#fff]">:</div>
          <div className={`${styles['dialog-block']} `}>{startWithTime(minutes)}</div>
          <div className="mx-[4px] !w-[fit-content] text-[#fff]">:</div>
          <div className={`${styles['dialog-block']}`}>{startWithTime(seconds)}</div>
        </div>
      )}
    </div>
  )
}

const BannerDown = ({ isShowDay, dayDiff, formattedRes }) => {
  const { hours, minutes, seconds } = formattedRes

  return (
    <div className="flex items-center">
      <div className="text-[12px] font-[500] text-[#fff] mr-[8px]">Expires in</div>
      {isShowDay ? (
        <div className="flex items-center ">
          <div className={`${styles['banner-block']}`}>{dayDiff}</div>
          <div className="ml-[8px] text-[#fff]">Days</div>
        </div>
      ) : (
        <div className="flex items-center ">
          <div className={`${styles['banner-block']}`}>{startWithTime(hours)}</div>
          <div className="mx-[4px] !w-[fit-content] text-[#fff]">:</div>
          <div className={`${styles['banner-block']}`}>{startWithTime(minutes)}</div>
          <div className="mx-[4px] !w-[fit-content] text-[#fff]">:</div>
          <div className={`${styles['banner-block']}`}>{startWithTime(seconds)}</div>
        </div>
      )}
    </div>
  )
}

export default function TimeDown(props) {
  const { targetDate, onEnd, type, onJump, lessDay = 7 } = props
  const onEndRef = useLatest(onEnd)

  // const [show, setShow] = useState(false)

  const [timeLeft, formattedRes, dayDiff] = useCountdown({
    targetDate: targetDate,
    onEnd: () => {
      console.log('倒计时结束')
      onEndRef.current?.()
    }
  })

  //是否展示天数
  const isShowDay = useMemo(() => {
    const showDay1 = dayDiff > 1 // 相差天数大于1
    const showDay2 = dayDiff === 1 && formattedRes.hours === 0 // 相差天数是1 但是在分秒还有
    const showDay3 = dayDiff === 1 && formattedRes.days !== 0 // 相差天数是1 但是在24小时内

    return showDay1 || showDay2 || showDay3
  }, [dayDiff, formattedRes.days, formattedRes.hours])

  const timeDownVisible = useMemo(() => {
    const dayisn3 =
      formattedRes.days === lessDay &&
      (formattedRes.hours !== 0 || formattedRes.minutes !== 0 || formattedRes.seconds !== 0)

    if (dayDiff > lessDay || dayisn3) {
      return false
    }

    const isShow1 =
      dayDiff === 0 &&
      (formattedRes.hours !== 0 || formattedRes.minutes !== 0 || formattedRes.seconds !== 0) // 0天但是可能有 时分秒

    return isShow1 || dayDiff !== 0
  }, [dayDiff, formattedRes.days, formattedRes.seconds, formattedRes.hours, formattedRes.minutes])

  const renderComponets = (type) => {
    const obj = {
      pc: <PcDown isShowDay={isShowDay} dayDiff={dayDiff} formattedRes={formattedRes} />,
      banner: <BannerDown isShowDay={isShowDay} dayDiff={dayDiff} formattedRes={formattedRes} />,
      mobile: <Down isShowDay={isShowDay} dayDiff={dayDiff} formattedRes={formattedRes} />,
      dialog: <DialogDown isShowDay={isShowDay} dayDiff={dayDiff} formattedRes={formattedRes} />
    }
    return (
      obj?.[type] || (
        <DialogDown isShowDay={isShowDay} dayDiff={dayDiff} formattedRes={formattedRes} />
      )
    )
  }
  return timeDownVisible ? (
    <div
      className="flex items-center"
      onClick={() => {
        onJump?.()
      }}
    >
      {renderComponets(type)}
    </div>
  ) : null
}
