import styles from './index.module.scss'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import BannerPic from '@images/banner/banner20230206.png'
import Banner2 from '@images/banner/banner2.png'
import Banner2Tip from '@images/banner/banner2_tip.png'
import icon from '/public/images/io.png'
import g2 from '/public/images/g2.png'
import { handleToPortal } from '@/utils/sys'
import { useRouter } from 'next/router'
import buriedSdk from '@/utils/zz-buried/index'
import { useWidth } from '@/utils/resize'
import { getLangLink } from '@/utils/lang'
type Props = {}
const Index = (props: Props) => {
  const { width, display } = useWidth()
  const { t, i18n } = useTranslation('home')
  const router = useRouter()
  const { locales, locale: activeLocale } = router
  const clickBanner = (text) => {
    buriedSdk.buried({
      event: `Shoplus官网_${width > 1024 ? 'Web' : 'H5'}`,
      scene: `BANNER_按钮点击_${text}`,
      URL: process.env.publicApiDomain
    })
    window.open(
      getLangLink('/tiktok-reports/tiktok-ecommerce-influencers-trend-report', activeLocale),
      '_blank'
    )
  }
  return (
    <div
      className={styles.main}
      style={{
        background: 'linear-gradient(117deg,#ffdbdd 0%,#ffc47d 100%)'
      }}
    >
      <div className={`defeaultContainer ${styles.top} cursor-pointer`}>
        <div className={`flex items-center justify-between ${styles.topwrap}`}>
          <div className={`${styles.left2} animate-box`}>
            <div className={styles.tips}>
              <Image
                layout="responsive"
                alt="TikTok e-commerce product trends"
                src={Banner2Tip}
              ></Image>
            </div>
            <div className={styles.title1}>
              {t('banner2Title1')} <br />
              {t('banner2Title2')}
            </div>
            <div className={styles.title2}>{t('banner2Title3')}</div>
            <div className={styles.title3}>{t('banner2Title4')}</div>
            <div
              className={styles.btn}
              onClick={() => {
                clickBanner('Download for FREE')
              }}
            >
              {t('Download for FREE')}
            </div>
          </div>
          <div className={`${styles.right2} animate-box`}>
            <div className={styles.pic}>
              <Image
                layout="responsive"
                alt="TikTok e-commerce product trends"
                src={Banner2}
              ></Image>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
