import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import BannerPic from "/public/images/ban002.png";
import icon from "/public/images/io.png";
import g2 from "/public/images/g2.png";
import pointerPng from "/public/images/home/TextImage/row-click.png";
import { handleToLogin } from "@/utils/sys";
import { useEffect, useState } from "react";
import buriedSdk from "@/utils/zz-buried/index";
import { useWidth } from "@/utils/resize";
type Props = {};
const Index = (props: Props) => {
  const { width, display } = useWidth();
  const { t, i18n } = useTranslation("home");
  const [isAuthorized, setAuthorized] = useState(false);
  const getPortalLink = (text, location = '/home') => {
    buriedSdk.buried({
      event: `Shoplus官网_${width > 1024 ? "Web" : "H5"}`,
      scene: `BANNER_按钮点击_${text}`,
      URL: process.env.publicApiDomain,
    });
    // @ts-ignore
    window.open(
      width > 1024 ? process.env.portalUrl! + location : location === '/home' ? process.env.mobileUrl : process.env.mobileUrl + "/rank?type=Videos&rankType=breakout", "_blank")
  };
  function getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  }
  useEffect(() => {
    // TODO: 注释登录
    const isAuth = getCookie("authorized-token") ? true : false;
    setAuthorized(isAuth);
  }, []);
  return (
    <div className={styles.main}>
      <div className={`defeaultContainer ${styles.top}`}>
        <div className={`flex items-center justify-between ${styles.topwrap}`}>
          <div className={`${styles.left} animate-box`}>
            <div className={`${styles.title1} ant`} dangerouslySetInnerHTML={{__html: t('bannerTitle1')}}>
            </div>
            <div
              className={`${styles.start} ant mr-[20px] relative`}
              onClick={() => {
                getPortalLink("For Seller", '/home');
              }}
            >
              {t("For Seller")}
              <div className="absolute w-[60px] h-[81px] top-[13px] -right-[9px] lg1024:hidden">
                <Image src={pointerPng} alt={'click'} />
              </div>
            </div>
            <div
              className={`${styles.start} ant relative`}
              onClick={() => {
                getPortalLink("For Creator", '/discovery/videos');
              }}
            >
              {t("For Creator")}
              <div className={styles.new}>
                { t('New') }
              </div>
            </div>
          </div>
          <div className={`${styles.right} animate-box`}>
            <div className={`${styles["tip-box"]} ant`}>
              <div className={styles.title}>
                <div className={styles.smallcn}>
                  <Image src={g2}></Image>
                </div>
                <span>{t("Sales")}</span>
              </div>
              <div className={styles.num}>35.68K</div>
            </div>
            <div className={styles.pic}>
              <Image
                layout="responsive"
                alt="TikTok analytics tool"
                src={BannerPic}
              ></Image>
            </div>
            <div className={`${styles["msk-box"]} ant`}>
              <div className={`${styles["msk-title"]}`}>{t("Revenue")}</div>
              <div className={`${styles["msk-desc"]}`}>$220,579</div>
              <div className={`${styles["msk-io"]}`}>
                <div className={`${styles["icon"]}`}>
                  <Image src={icon}></Image>
                </div>
              </div>
              <div className={`${styles["line-card"]} animate-box cm-in-wait`}>
                <div className={`${styles["line"]} ant-y`}></div>
                <div className={`${styles["line"]} ant-y`}></div>
                <div className={`${styles["line"]} ant-y`}></div>
                <div className={`${styles["line"]} ant-y`}></div>
                <div className={`${styles["line"]} ant-y`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
