import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useLatest } from 'ahooks'

/**
  const [timeLeft, formattedRes, dayDiff] = useCountdown({
    targetDate: targetDate,
    onEnd: () => {
      console.log('倒计时结束')
      onEndRef.current?.()
    }
  })
 const { hours, minutes, seconds } = formattedRes
 */

export const isNumber = (value: unknown): value is number => typeof value === 'number'

export type TDate = dayjs.ConfigType

export interface Options {
  leftTime?: number
  targetDate?: TDate
  interval?: number
  onEnd?: () => void
  onTimeDown?: () => void
}

export interface FormattedRes {
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

const calcLeft = (target?: TDate) => {
  if (!target) {
    return 0
  }
  // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
  const left = dayjs(target).valueOf() - Date.now()
  return left < 0 ? 0 : left
}

const parseMs = (milliseconds: number): FormattedRes => {
  return {
    days: Math.floor(milliseconds / 86400000),
    hours: Math.floor(milliseconds / 3600000) % 24,
    minutes: Math.floor(milliseconds / 60000) % 60,
    seconds: Math.floor(milliseconds / 1000) % 60,
    milliseconds: Math.floor(milliseconds) % 1000
  }
}

export const useCountdown = (options: Options = {}) => {
  const { leftTime, targetDate, interval = 1000, onEnd, onTimeDown } = options || {}

  const target = useMemo<TDate>(() => {
    if ('leftTime' in options) {
      return isNumber(leftTime) && leftTime > 0 ? Date.now() + leftTime : undefined
    } else {
      return targetDate
    }
  }, [leftTime, targetDate])

  const [timeLeft, setTimeLeft] = useState(() => calcLeft(target))

  const onEndRef = useLatest(onEnd)
  const onTimeDownRef = useLatest(onTimeDown)

  useEffect(() => {
    if (!target) {
      // for stop
      setTimeLeft(0)
      return
    }

    // 立即执行一次
    setTimeLeft(calcLeft(target))

    const timer = setInterval(() => {
      const targetLeft = calcLeft(target)
      setTimeLeft(targetLeft)
      onTimeDownRef.current?.()
      if (targetLeft === 0) {
        clearInterval(timer)
        onEndRef.current?.()
      }
    }, interval)

    return () => clearInterval(timer)
  }, [target, interval])

  const formattedRes = useMemo(() => parseMs(timeLeft), [timeLeft])

  //日期天数diff 不考虑时分秒情况下
  const dayDiff = useMemo(() => {
    if (!targetDate) {
      return 0
    }
    const nowTime = dayjs(new Date()).format('YYYY-MM-DD')
    const endTime = dayjs(targetDate).format('YYYY-MM-DD')

    const diff = dayjs(endTime).diff(nowTime, 'day')

    return diff
  }, [targetDate, timeLeft])

  return [timeLeft, formattedRes, dayDiff] as const
}
