import styles from './index.module.scss'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import BannerPic from '@images/banner/banner20230206.png'
import Banner1 from '@images/banner/b1.png'
import icon from '/public/images/io.png'
import g2 from '/public/images/g2.png'
import { handleToPortal } from '@/utils/sys'
import { useRouter } from 'next/router'
import buriedSdk from '@/utils/zz-buried/index'
import { useWidth } from '@/utils/resize'
import { getLangLink } from '@/utils/lang'
type Props = {}
const Index = (props: Props) => {
  const { width, display } = useWidth()
  const { t, i18n } = useTranslation('home')
  const router = useRouter()
  const { locales, locale: activeLocale } = router
  const clickBanner = (text, link) => {
    buriedSdk.buried({
      event: `Shoplus官网_${width > 1024 ? 'Web' : 'H5'}`,
      scene: `BANNER_按钮点击_${text}`,
      URL: process.env.publicApiDomain
    })
    window.open(
      link,
      '_blank'
    )
  }
  return (
    <div
      className={`${styles.main} ${styles['main_b1']}`}
      style={{
        background: 'linear-gradient(120deg, #FFE8D4, #FFB1B2);'
      }}
    >
      <div className={`defeaultContainer ${styles.top} cursor-pointer`}>
        <div className={`flex items-center ${styles.topwrap}`}>
          <div className={`${styles.left2} animate-box`}>
        
            <div className={ `${styles.title1} ${styles['b1_title']}`}>
             
              {t('banner1T')}
              {t('banner1T2')}
            </div>
   
            <div
              className={`${styles.btn} ${styles['btn_b1']}`}
              onClick={() => {
                clickBanner('click Questionnaire', t('banner1TLink'))
              }}
            >
              {t('banner1TGO')}
            </div>
          </div>
          <div className={`${styles.right2} animate-box`}>
            <div className={styles.pic}>
              <Image
                layout="responsive"
                alt="TikTok e-commerce product trends"
                src={Banner1}
              ></Image>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
