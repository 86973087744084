import styles from './index.module.scss'
import { useWidth } from '@/utils/resize'
import { useTranslation } from 'next-i18next'
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/mousewheel'
import Image from 'next/image'
import banner from '@images/banner/banner202322.png'
import { useEffect, useState, useMemo } from 'react'
import Banner from '@home3/pc/banner'
import Banner2 from '@home3/pc/banner/banner2'
import Banner1 from '@home3/pc/banner/banner1'
import Activity from '@home3/pc/banner/Activity'
type Props = {
  info: any
}
const Index = (props: Props) => {
  // console.log('~~~~', props.info)

  const { t, i18n } = useTranslation('home')
  const { width, display } = useWidth()

  const clickBanner = () => {
    window.location.href = 'https://www.shoplus.net/blogs/product-trends-report-22sale'
  }

  const bannerList = useMemo(() => {
    const list = props.info.bannerList
    // if (activityDown && list.find((item) => item === 'Activity')) {
    //   list.splice(
    //     list.findIndex((item) => item === 'Activity'),
    //     1
    //   )
    // }
    return list
  }, [props.info.bannerList])
  const renderComponents = (type) => {
    const obj = {
      Activity: <Activity info={props.info.discountInfo} infoData={props.info.infoData} />,
      Banner1: <Banner1 />,
      Banner2: <Banner2 />,
      Banner: <Banner />
    }
    return obj[type]
  }
  return (
    <div className={styles.anli} id="anli">
      <div className=" overflowHidden relative h-full">
        {display ? (
          <Swiper
            className="h-full"
            modules={[Pagination, Navigation, Mousewheel, Autoplay]}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
              bulletClass: ` ${styles.myBullet} swiper-pagination-bullet`,
              bulletActiveClass: `${styles.myActiveBullet} swiper-pagination-bullet-active`
            }}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
              disabledClass: `swiper-button-disabled ${styles.myNavigationDisabled}`
            }}
            autoplay={false}
            // loop={true}
          >
            {bannerList.map((b) => {
              return (
                <SwiperSlide key={b}>
                  <div className={styles.main}>{renderComponents(b)}</div>
                </SwiperSlide>
              )
            })}

            <div className={`swiper-pagination ${styles.myPagination}`}></div>
            {/* <div className={`swiper-button-prev ${styles.myNavigationPrev}`}></div>
            <div className={`swiper-button-next ${styles.myNavigationNext}`}></div> */}
          </Swiper>
        ) : null}
      </div>
    </div>
  )
}
export default Index
